import React from 'react'
import { graphql, Link } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import '../styles/productPage.scss'

import Seo from '../component/Seo'
import Layout from '../templates/Layout'
import Form from '../component/mainPage/callbackForm/CallbackForm'
import Navigation from '../component/navigation/Navigation'
import Footer from '../component/mainPage/footer/Footer'

function Product({ data }) {


  const { name, description, cost, time, imageDescription, conditions } = data.mobileDataJson;

  return (
    <div className="productPage">
      <Seo title={name} />
      <Navigation />
      <Layout styleName="product">
        <div className="product__info">
          <h1>{name}</h1>
          <Link className="backPage" to="/outdoor/">Вернуться назад</Link>
          <div className="images">
            {imageDescription.map(descImage => {
              const imgDescSrc = getImage(descImage)

              return <GatsbyImage image={imgDescSrc} alt={name} />
            })}
          </div>
          <p><span>Цена:</span> {cost}</p>
          <p><span>Сроки:</span> {time}</p>
          <div className="description">
            {description.map(descText => {

              return <p>{descText}</p>
            })}
          </div>
        </div>
        <div className="product__order">
          <Form title="Оставьте заявку и мы с Вами свяжемся" button="Оформить заявку" message="Опишите Ваш заказ" formName={name} />
          <div className="conditions">
            <h3>Преимущества работы с нами</h3>
            {conditions.map(conditionsText => {

              return <p>{conditionsText}</p>
            })}
          </div>
        </div>
      </Layout>
      <div className="footerContainer">
        <Footer />
      </div>
    </div>
  )
}

export default Product

export const query = graphql`
query MobileQuery($url: String) {
  mobileDataJson(url: {eq: $url}) {
    url
    name
    time
    cost
    description
    conditions
    imageDescription {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 85, placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
  }
}
`